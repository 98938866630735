import { theme } from '../theme';
import { addScript } from '../utils/domUtils';
import { conf } from './configService';
import { consentService } from './consentService';
import { domainService } from './domainService';
import { eventService } from './eventService';

// let displayAlreadyExecuted = false;
// let adSlotsPendingToDisplay = false;
const slotMetadata = {};

/**
 * Google Publisher Tag Service
 */
export const gptService = {
  registerEventListeners: () => {
    const initGptScript = () => {
      if (consentService.allowsTargeting()) {
        gptService.loadGptScript();

        // gptService.display();
        /*       document.addEventListener('gatsbyOnPreRouteUpdate', gptService.reload);
      document.addEventListener('transporterRenderingStart', () => {
        gptService.updatePvsid();
        gptService.updateCorrelator();
      }); */
      }
    };

    // gptService.addWrapperToObjectDefineProperty();

    eventService.addEventListener('OneTrustInteractionDone', initGptScript, {
      once: true
    });
  },

  /*   removeGptScript: () => {
    const gptScripts = document.querySelectorAll(
      "script[src='https://securepubads.g.doubleclick.net/tag/js/gpt.js']"
    );
    gptScripts.forEach(gptScript =>
      gptScript.parentNode.removeChild(gptScript)
    );
  }, */

  loadGptScript: attributes => {
    addScript(
      'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      attributes
    );
  },

  init: () => {
    window.googletag.cmd.push(() => {
      // Set limited ads (ltd param).
      // @see https://developers.google.cn/interactive-media-ads/docs/sdks/html5/client-side/limited-ads
      // window.googletag.pubads().setPrivacySettings({
      //   limitedAds: !consentService.allowsTargeting()
      // });

      // Enable lazy loading.
      // @see https://developers.google.com/doubleclick-gpt/reference#enablelazyloadopt_config
      window.googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 50,
        renderMarginPercent: 50,
        mobileScaling: 2.0
      });

      // Enable SRA and services.
      // Disabled on https://jira.disney.com/browse/FNGS-1968
      // window.googletag.pubads().enableSingleRequest();

      // Add event listener to detect slot visibility
      window.googletag
        .pubads()
        .addEventListener('slotVisibilityChanged', event => {
          const slotId = event.slot.getSlotElementId();
          const isVisible = event.inViewPercentage > 0;
          if (isVisible) {
            // visible and firstVisible are both timestamps
            const visible = gptService.getSlotMetadata(slotId, 'visible');
            const firstVisible = gptService.getSlotMetadata(
              slotId,
              'firstVisible'
            );
            const timestamp = Date.now();
            if (!visible) {
              gptService.setSlotMetadata(slotId, 'visible', timestamp);
              document.dispatchEvent(
                new CustomEvent('adSlotVisible', { detail: { slotId } })
              );
            }
            if (!firstVisible) {
              gptService.setSlotMetadata(slotId, 'firstVisible', timestamp);
            }
          } else {
            gptService.setSlotMetadata(slotId, 'visible', null);
            gptService.setSlotMetadata(slotId, 'visible', null);
          }
        })
        .collapseEmptyDivs(false);

      // Dispatch an event if slot is empty, only for stage and local environments.
      if (
        conf.isStage ||
        domainService.isLocalDomain(window.location.hostname)
      ) {
        window.googletag.pubads().addEventListener('slotRenderEnded', event => {
          const slotId = event.slot.getSlotElementId();
          if (event.isEmpty) {
            document.dispatchEvent(
              new CustomEvent('adSlotEmpty', { detail: { slotId } })
            );
          }
        });
      }

      window.googletag.enableServices();

      // Handle instant navigation (route updates)
      document.addEventListener('gatsbyOnRouteUpdate', () => {
        // gptService.updateCorrelator();
        gptService.display();
      });

      /*
      // Handle transporter. Transporter pagination renders before
      // "gatsbyOnRouteUpdate" event is fired, because it's rendered a
      // little bit before reaching the end of the page.
      document.addEventListener('transporterRenderingDone', () => {
        gptService.display();
      }); */
    });
  },

  /**
   * Wrapper to push a slot definition and control later display() calls
   *
   * @param {function} cmd - A function to be executed
   * @returns {boolean}
   */
  pushSlot: cmd => {
    // adSlotsPendingToDisplay = true;
    return window.googletag.cmd.push(cmd);
  },

  /**
   * Destroy an adSlot
   *
   * @param {object} adSlot - Slot to be destroyed
   * @returns {boolean}
   */
  destroySlot: adSlot => {
    delete slotMetadata[adSlot.getSlotElementId()];
    return (
      window.googletag.destroySlots && window.googletag.destroySlots([adSlot])
    );
  },

  /**
   * Get and Ad id (header) from a slot id (gpt--header--12345)
   *
   * Ad ids are used in the ad container using one of these two formats:
   *  1) "gpt--{adId__variant}--*"
   *  2) "gpt--{adId__variant}"
   *
   * We parse that container id (obtained from getSlotElementId()) to get
   * the Ad id.
   *
   * @param {string} adSlotId - Ad slot id (i.e.- gpt--header--12345)
   * @returns {string}
   */
  getAdIdFromSlotId: adSlotId =>
    adSlotId.replace(/^gpt--([\w_]+)-?.*/, '$1').replace(/^(.*)__.*/, '$1'),

  /**
   * Get the position of a given adSlot in the current set of adSlots
   *
   * @param {string} adSlotId - Ad slot id (i.e.- gpt--header--12345)
   * @returns {number}
   */
  getAdSlotPosition: adLostId => {
    const adId = gptService.getAdIdFromSlotId(adLostId);
    const adSlotsByAdId = gptService.getAdSlotsByAdId(adId);
    return (
      adSlotsByAdId.findIndex(
        adSlot => adSlot.getSlotElementId() === adLostId
      ) + 1
    );
  },

  /**
   * Get all adSlots based on their Ad id (i.e.- header) that are currently present
   * and loaded by GPT.
   *
   * @param {string} adId - Ad id (i.e.- header)
   * @returns {Array}
   */
  getAdSlotsByAdId: adId =>
    window.googletag
      .pubads()
      .getSlots()
      .filter(
        slot => gptService.getAdIdFromSlotId(slot.getSlotElementId()) === adId
      ),

  /**
   * Get slot metadata
   *
   * @param {string} adSlotId - Ad slot id (i.e.- gpt--header--12345)
   * @param {string} key - Metadata key
   * @returns {mixed}
   */
  getSlotMetadata: (adSlotId, key) =>
    slotMetadata[adSlotId] ? slotMetadata[adSlotId][key] : null,

  /**
   * Set slot metadata
   *
   * @param {string} adSlotId - Ad slot id (i.e.- gpt--header--12345)
   * @param {string} key - Metadata key
   * @param {mixed} value - Metadata value
   */
  setSlotMetadata: (adSlotId, key, value) => {
    if (!slotMetadata[adSlotId]) {
      slotMetadata[adSlotId] = {};
    }
    slotMetadata[adSlotId][key] = value;
  },

  /**
   * Reset slot metadata
   *
   * @param {string} adSlotId - Ad slot id (i.e.- gpt--header--12345)
   */
  resetSlotMetadata: adSlotId => {
    slotMetadata[adSlotId] = {};
  },

  /**
   * Reset slot metadata
   *
   * @param {string} adSlotId - Ad slot id (i.e.- gpt--header--12345)
   */
  get: adSlotId => {
    slotMetadata[adSlotId] = {};
  },

  /**
   * Display all adSlots present on page and not already displayed.
   *
   * Since SRA is enabled, displaying one adSlot automatically displays all
   * adSlots. Anyway, executing googletag.display() on a slot already displayed
   * doesn't display other adSlots not yet displayed.
   *
   * In most cases, it's enough to call display() without arguments, and it
   * will find the last adSlot (let's assume that, being the last one, it's not
   * displayed yet), hence displaying all non-displayed adSlots.
   *
   * This is the case when first load happens or a navigation happens, and WE
   * CAN WAIT until all adSlots are defined to call display().
   *
   * In other cases, it's useful to pass an adSlot as an argument. This is the
   * case when a page is completely loaded, and a resize event triggers a
   * breakpoint change. At that moment, one or several <Ad/> components will
   * mount, but there is no way to know when all of them have successfully
   * defined their adSlots. So WE CAN'T WAIT and we need to call display()
   * individually per each adUnit.
   *
   * @param {object} adSlot - Ad slot
   */
  display: adSlot => {
    /*
    if (!gptService.areAdSlotsPendingToDisplay()) {
      return;
    }
    */
    window.googletag.cmd.push(() => {
      let slotToDisplay = adSlot;
      if (!slotToDisplay || !slotToDisplay.getSlotElementId) {
        const slots = window.googletag.pubads().getSlots();
        slotToDisplay = slots[slots.length - 1];
      }
      // displayAlreadyExecuted = true;
      // adSlotsPendingToDisplay = false;
      window.googletag.display(slotToDisplay);
    });
  },

  /**
   * Refreshes a single adUnit
   *
   * @param {object} adSlot - Ad slot
   */
  refresh: adSlot => {
    if (window.googletag && window.googletag.pubadsReady) {
      window.googletag.pubads().refresh([adSlot]);
    }
  },

  /**
   * Refreshes a single adUnit on a breakpoint change.
   *
   * @param {object} adSlot - Ad slot
   */
  refreshBreakpoint: adSlot => {
    if (window.googletag && window.googletag.pubadsReady) {
      const slotId = adSlot.getSlotElementId();
      window.googletag.pubads().refresh([adSlot]);
      gptService.resetSlotMetadata(slotId);
    }
  },

  pubadsReady: () => window.googletag && window.googletag.pubadsReady,

  /**
   * Get a mapping between viewport size and ad size
   * @see https://developers.google.com/doubleclick-gpt/guides/ad-sizes#responsive_ads
   *
   * @param {object} adSizes - Object with breakpoint names as properties, and ad sizes as values
   */
  getSizeMapping: adSizes => {
    // If data for all breakpoints is the same, return null
    if (
      adSizes.mobile === adSizes.tablet &&
      adSizes.mobile === adSizes.small_desktop &&
      adSizes.mobile === adSizes.large_desktop
    ) {
      return null;
    }

    const sizeMapping = window.googletag.sizeMapping();
    theme.breakpoints.sorted.forEach(bp => {
      const previousBp = theme.breakpoints.sorted[bp.index - 1];
      const previousBpWidth = previousBp ? previousBp.width : 0;
      const bpViewPort = [previousBpWidth, 0];
      if (adSizes[bp.name]) {
        sizeMapping.addSize(bpViewPort, adSizes[bp.name]);
      } else {
        sizeMapping.addSize(bpViewPort, []);
      }
    });
    return sizeMapping.build();
  }
  /*
  ,

  isDisplayAlreadyExecuted: () => displayAlreadyExecuted,

  areAdSlotsPendingToDisplay: () => adSlotsPendingToDisplay

     addWrapperToObjectDefineProperty: () => {
    // Add a wrapper over Object.defineProperty to be able to generate
    // a new "goog_pvsid" on page route changes
    const originalDefineProperty = window.Object.defineProperty;
    window.Object.defineProperty = (obj, prop, descriptor) => {
      if (obj === window && prop === 'goog_pvsid') {
        originalDefineProperty(obj, prop, {
          ...descriptor,
          configurable: true
        });
      } else {
        originalDefineProperty(obj, prop, descriptor);
      }
    };
  }, */

  // Keep this logic, even commented, to be able to easily restore it in the future
  /*     reload: () => {
    if (window.googletag?.destroySlots) {
      window.googletag.destroySlots();
    }
    delete window.googletag;
    window.googletag = window.googletag || { cmd: [] };
    gptService.removeGptScript();
    gptService.loadGptScript();
    gptService.updatePvsid();
    gptService.updateCorrelator();
    gptService.init();
    gptService.display();
  }, */

  /*   updateCorrelator: () => {
    if (window.googletag?.pubads) {
      window.googletag.pubads().updateCorrelator();
    }
  } */

  /*   updatePvsid: () => {
    window.Object.defineProperty(window, 'goog_pvsid', {
      // eslint-disable-next-line no-restricted-properties
      value: Math.floor(Math.random() * Math.pow(2, 52)),
      configurable: true
    });
  } */
};
